import ColCs from 'components/Common/ColCs'
import { SectionWrapper } from 'components/Common/Containers'
import RowCs from 'components/Common/RowCs'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Service1 } from "assets/svg/services/service1.svg";
import { ReactComponent as Service2 } from "assets/svg/services/service2.svg";
import { ReactComponent as Service3 } from "assets/svg/services/service3.svg";
import { ReactComponent as Service4 } from "assets/svg/services/service4.svg";
import { ReactComponent as Service5 } from "assets/svg/services/service5.svg";

const serviceImages = [
  Service1, Service2, Service3, Service4, Service5, Service1
]
const Gallery = () => {
  return (
    <GalleryWrapper>
      <RowCs noSpace={true} className={'row-gallery'}>
        {serviceImages.map((item, index) => {
          const image = {src :item}
          return (
            <ColCs xs={2} key={index} noGutter={true} className={'col-gallery'}>
              <BoxImg>
                <image.src />
              </BoxImg>
            </ColCs>
          )
        })}
      </RowCs>
    </GalleryWrapper>
  )
}
const GalleryWrapper = styled(SectionWrapper)`
  .row-gallery {
    margin-left: 0;
    margin-right: 0;
  }
  .col-gallery {
    padding-left: 0;
    padding-right: 0;
  }
`
const BoxImg = styled.div`
  width: 100%;
  max-height: 250px;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    path {
      fill: ${({ theme }) => theme.primaryShade };
      stroke: ${({ theme }) => theme.primary };
    }
  }
  &:hover{
    svg {
      path {
        fill: ${({ theme }) => theme.primary };
        stroke: ${({ theme }) => theme.primary };
      }
    }
  }
`
export default Gallery
