import Card from 'components/Common/Card'
import { ReactComponent as Service0 } from "assets/svg/services/service2.svg";
import { ReactComponent as Service1 } from "assets/svg/services/service3.svg";
import { ReactComponent as Service2 } from "assets/svg/services/service4.svg";
import { ReactComponent as Service3 } from "assets/svg/services/service5.svg";
import { ReactComponent as Service4 } from "assets/svg/services/service1.svg";

import { Section, SectionWrapper } from 'components/Common/Containers'
import { TextNormal } from 'components/Common/Typography'
import HeadingSection from 'components/Sections/HeadingSection'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { removeDoubleApostrophe } from 'utils/removeDoubleApostrophe'
import Carousel from "react-multi-carousel";
import {responsive} from "constants/Carousel";

const serviceImages = [
  Service0, Service1, Service2, Service3, Service4
]

const Services = () => {
  const { t } = useTranslation()
  return (
    <OurServiceWrapper id={'service-section'}>
      <Section>
        <DivFlex>
          <RichTextLeft>
              <HeadingSection content={t('home.ourService.heading')} />
              <Description>{t('home.ourService.description')}</Description>
          </RichTextLeft>
          <ServicesContainer>
            <Carousel responsive={responsive}
                      showDots={true}
                      arrows={false}
                      dotListClass={'dot-list'}
            >
              {Array.from(Array(5).keys()).map((index: number) => {
                const title = removeDoubleApostrophe(JSON.stringify(t(`home.ourService.services.${index}.title`)))
                const text = removeDoubleApostrophe(JSON.stringify(t(`home.ourService.services.${index}.text`)))
                const service = {
                  title,
                  text,
                  image: serviceImages[index],
                }
                return (
                  <CardWrapper key={index}>
                    <Card service={service} />
                  </CardWrapper>
                )
              })}
            </Carousel>
          </ServicesContainer>
        </DivFlex>
      </Section>
    </OurServiceWrapper>
  )
}
const OurServiceWrapper = styled(SectionWrapper)`
  width: 100%;
`
const DivFlex = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`
const RichTextLeft = styled.div`
  width: 30%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`
const Description = styled(TextNormal)`
  margin-top: 16px;
`
const ServicesContainer = styled.div`
  width: 70%;
  .dot-list {
    .react-multi-carousel-dot button {
      background-color: ${({ theme }) => theme.primaryShade };
      border: none;
    }
    .react-multi-carousel-dot--active button {
      background-color: ${({ theme }) => theme.primary };
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`

const CardWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  padding-right: 20px;
  margin-bottom: 30px;
`
export default Services
