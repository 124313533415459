import Gallery from './Gallery'
import Hero from './Hero'
import Services from './Services'
import Partners from './Partners'
import Testimonials from './Testimonials'

const Home = () => {
  return (
    <div>
      <Hero />
      <Services />
      <Gallery />
      <Testimonials />
      <Partners />
    </div>
  )
}

export default Home
