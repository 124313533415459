import { IMAGES } from 'assets/images'
import { Section, SectionWrapper } from 'components/Common/Containers'
import { Heading4, TextNormal } from 'components/Common/Typography'
import HeadingSection from 'components/Sections/HeadingSection'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {responsive} from "constants/Carousel";
import Carousel from "react-multi-carousel";

const dataSlide = [
  {
    user: {
      image: IMAGES.TESTIMONIAL1,
      name: 'Rodrigo Quan Miranda',
      position: 'Co-founder @MantraDAO',
    },
  },
  {
    user: {
      image: IMAGES.TESTIMONIAL2,
      name: 'Rene Labarthe',
      position: 'President of International Affairs @<br/>BLOK BioScience LatAm',
    },
  },
  {
    user: {
      image: IMAGES.TESTIMONIAL3,
      name: 'Dao Duy Tan',
      position: 'Founder @Evotek Vietnam JSC',
    },
  },
]
const Testimonials = () => {
  const { t } = useTranslation()
  const [heightCard, setHeightCard] = useState<undefined | number>(undefined)

  return (
    <TestimonialsWrapper>
      <Section>
        <DivFlex>
          <RichTextLeft>
              <HeadingSection content={t('home.testimonials.heading')} />
              <Description>{t('home.testimonials.description')}</Description>
          </RichTextLeft>
          <ListCard>
            <Carousel responsive={responsive}
                      showDots={true}
                      arrows={false}
                      dotListClass={'dot-list'}
            >
              {dataSlide.map((data, index) => {
                const { user } = data
                return (
                  <CardItem key={index}>
                    <CardItemBox heightCard={heightCard}>
                      <InfoUser>
                        <img src={user.image} alt={'image_user'} />
                      </InfoUser>
                      <InfoAnother>
                        <Name>{user.name}</Name>
                        <PositionText dangerouslySetInnerHTML={{ __html: user.position }} />
                        <Quote>{t(`home.testimonials.dataSlide.${index}.quote`)}</Quote>
                      </InfoAnother>
                    </CardItemBox>
                  </CardItem>
                )
              })}
            </Carousel>
          </ListCard>
        </DivFlex>
      </Section>
    </TestimonialsWrapper>
  )
}
const TestimonialsWrapper = styled(SectionWrapper)`
  width: 100%;
`
const DivFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const RichTextLeft = styled.div`
  flex: 0 0 300px;
  max-width: 300px;
  padding-right: 12px;
  margin-top: 20px;
`
const Description = styled(TextNormal)`
  margin-top: 16px;
`
const ListCard = styled.div`
  flex: 0 0 calc(100% - 300px);
  max-width: calc(100% - 300px);
  .dot-list {
    .react-multi-carousel-dot button {
      background-color: ${({ theme }) => theme.primaryShade };
      border: none;
    }
    .react-multi-carousel-dot--active button {
      background-color: ${({ theme }) => theme.primary };
    }
  }
  @media screen and (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 40px;
  }
`
const CardItem = styled.div`
  padding-right: 15px;
  padding-bottom: 35px;
  height: 100%;
`
const CardItemBox = styled.div<{ heightCard: undefined | number }>`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.primaryShade};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  height: ${({ heightCard }) => (heightCard ? `${heightCard}px` : '100%')};
  &:hover {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`
const InfoUser = styled.div`
  img {
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto;
    border-radius: 200px;
    object-fit: cover;
  }
`
const Quote = styled.p`
  font-size: ${({ theme }) => theme.fontSizeText3};
  color: ${({ theme }) => theme.gray};
  line-height: 17px;
  text-align: center;
`
const InfoAnother = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const Name = styled(Heading4)`
  color: ${({ theme }) => theme.primary};
  text-align: center;
`
const PositionText = styled.p`
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 17px;
  text-align: center;
`
export default Testimonials
