import { Sun } from 'components/Animated/Sun'
import { ButtonPrimary } from 'components/Common/Button'
import ColCs from 'components/Common/ColCs'
import { Section } from 'components/Common/Containers'
import RowCs from 'components/Common/RowCs'
import { Display1, Heading2, TextLarge } from 'components/Common/Typography'
import { PathConstants } from 'constants/PathConstants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Hero = () => {
  const { t } = useTranslation()
  return (
    <HeroWrapper id={'hero-section'}>
      <Section>
        <RowCs space={-17}>
          <ColCs gutter={17} xs={12} md={7} className={'col-cs'}>
            <ContentBox>
                <SubTitle>{t('home.hero.subTitle')}</SubTitle>
                <Title>{t('home.hero.title')}</Title>
                <Description>{t('home.hero.description')}</Description>
              <Link to={'https://cal.com/team/dandelionlabs/onboarding'} target="_blank" rel="noopener noreferrer">
                <ButtonPrimary>{t('button.contactUs')}</ButtonPrimary>
              </Link>
            </ContentBox>
          </ColCs>
          <ColCs gutter={17} xs={12} md={5} className={'col-cs'}>
            <ImageBox>
              <Sun />
            </ImageBox>
          </ColCs>
        </RowCs>
      </Section>
    </HeroWrapper>
  )
}
const HeroWrapper = styled.div`
  padding-bottom: 150px;
  @media only screen and (max-width: 991px) {
    padding-bottom: 80px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 40px;
    .col-cs {
      &:first-of-type {
        order: 2;
      }
      &:last-of-type {
        order: 1;
      }
    }
  }
`
const ContentBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
`
const SubTitle = styled(Display1)`
  color: ${({ theme }) => theme.primary};
`
const Title = styled(Heading2)`
  max-width: 484px;
`
const Description = styled(TextLarge)`
  max-width: 500px;
`
const ImageBox = styled.div`
  img {
    display: block;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 250px;
    margin: 0 auto;
  }
  @media screen and (max-width: 575px) {
    width: 160px;
    margin-bottom: 16px;
  }
`
export default Hero
