import { ButtonPrimary } from 'components/Common/Button'
import CardDotted from 'components/Common/CardDotted'
import ColCs from 'components/Common/ColCs'
import { Section } from 'components/Common/Containers'
import { FormGroup, FormInput, FormLabel, FormTextarea } from 'components/Common/Form'
import Loader from 'components/Common/Loader'
import RowCs from 'components/Common/RowCs'
import { ToastError, ToastSuccess } from 'components/Common/ToastMessage'
import { Display1, Heading2, TextLarge, TextNormal } from 'components/Common/Typography'
import { FormikValues, useFormik } from 'formik'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled, { css } from 'styled-components'
import * as Yup from 'yup'

import { useSendContactMutation } from '../../state/contactState'

interface ISubject {
  value: string
  label: string
}
const enum SUBJECT {
  DEVELOPER = 'DEVELOPER',
  INVEST = 'INVEST',
  ADVISORY = 'ADVISORY',
}
const subjects = [
  {
    value: SUBJECT.DEVELOPER,
    label: 'I want to developer',
  },
  {
    value: SUBJECT.INVEST,
    label: 'I want to invest',
  },
  {
    value: SUBJECT.ADVISORY,
    label: 'I want to advisory',
  },
]
const Contact = () => {
  const { t } = useTranslation()
  const [subject, setSubject] = useState<ISubject>(subjects[0])
  const [openSubjects, setOpenSubjects] = useState<boolean>(false)
  const refDropdownSubject = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(refDropdownSubject, () => setOpenSubjects(false))
  const [sendContact] = useSendContactMutation()
  const formikContact = useFormik({
    initialValues: {
      userName: '',
      userEmail: '',
      message: '',
      phoneNumber: '',
    },
    validationSchema: Yup.object().shape({
      userName: Yup.string().required('Name is required'),
      userEmail: Yup.string()
        .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Invalid email')
        .required('Email is required'),
    }),
    onSubmit: (values: FormikValues) => {
      handleClick(values)
    },
  })
  const { handleChange, values, setSubmitting, isSubmitting, resetForm, errors, handleBlur, isValid, dirty } =
    formikContact
  const handleClick = (values: FormikValues) => {
    const { userName, subject, userEmail, message } = values
    ;(async () => {
      setSubmitting(true)
      try {
        const dataSend = {
          name: userName,
          subject,
          message,
          email: userEmail,
        }
        const response: any = await sendContact(dataSend)
        if ('data' in response) {
          toast.success(<ToastSuccess>Send contact success</ToastSuccess>, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          })
        } else {
          toast.error(<ToastError>{t('toast.tryAgain')}</ToastError>, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          })
        }
      } catch {
        toast.error(<ToastError>{t('toast.tryAgain')}</ToastError>, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        })
      } finally {
        setSubmitting(false)
        resetForm()
      }
    })()
  }
  return (
    <ContactUsWrapper>
      <Section>
        <Introduce>
            <HighLight>{t('contact.introduce.highlight')}</HighLight>
            <Title>{t('contact.introduce.title')}</Title>
            <Description>{t('contact.introduce.description')}</Description>
        </Introduce>
        <ListContact>
          <RowCs space={-20}>
            <ColCs gutter={20} xs={12} sm={6} lg={4} className={'col-cs'}>
              <CardDotted>
                <CardContent>
                  <Illustrator>
                    <img src={'/assets/svg/icon-email.svg'} alt={'icon-email'} />
                  </Illustrator>
                  <a href="mailto:hello@dandelionlabs.io">hello@dandelionlabs.io</a>
                </CardContent>
              </CardDotted>
            </ColCs>
            <ColCs gutter={20} xs={12} sm={6} lg={4} className={'col-cs'}>
              <CardDotted>
                <CardContent>
                  <Illustrator>
                    <img src={'/assets/svg/icon-phone.svg'} alt={'icon-phone'} />
                  </Illustrator>
                  <a href="tel:+840343788923">+84 0343 788923</a>
                </CardContent>
              </CardDotted>
            </ColCs>
            <ColCs gutter={20} xs={12} sm={6} lg={4} className={'col-cs'}>
              <CardDotted>
                <CardContent>
                  <Illustrator>
                    <img src={'/assets/svg/icon-map.svg'} alt={'icon-map'} />
                  </Illustrator>
                  <span>
                    Kim Hoan Building
                    <br />
                    Ngo 19 Duy Tan
                    <br />
                    Cau Giay, Hanoi
                  </span>
                </CardContent>
              </CardDotted>
            </ColCs>
          </RowCs>
        </ListContact>
      </Section>
      <Section>
        <FormContact>
          <RowCs space={-20}>
            <ColCs gutter={20} xs={12} md={6}>
              <FormGroup>
                <FormLabel>
                  {t('contact.form.name.label')} <Asterisk>*</Asterisk>
                </FormLabel>
                <FormInput
                  placeholder={t('contact.form.name.placeholder')}
                  type={'text'}
                  autoComplete={'off'}
                  id={'userName'}
                  name={'userName'}
                  value={values.userName ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors?.userName && values.userName !== '' && <MessageError>{errors.userName as string}</MessageError>}
              </FormGroup>
              <FormGroup>
                <FormLabel>
                  {t('contact.form.email.label')} <Asterisk>*</Asterisk>
                </FormLabel>
                <FormInput
                  placeholder={t('contact.form.email.placeholder')}
                  type={'text'}
                  autoComplete={'off'}
                  id={'userEmail'}
                  name={'userEmail'}
                  value={values.userEmail ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors?.userEmail && values.userName !== '' && (
                  <MessageError>{errors.userEmail as string}</MessageError>
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel>{t('contact.form.phoneNumber.label')}</FormLabel>
                <FormInput
                  placeholder={t('contact.form.phoneNumber.placeholder')}
                  type={'number'}
                  autoComplete={'off'}
                  id={'phoneNumber'}
                  name={'phoneNumber'}
                  value={values.phoneNumber ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t('contact.form.subject.label')}</FormLabel>
                <DropdownSubject ref={refDropdownSubject}>
                  <OptionSubjectChoose onClick={() => setOpenSubjects(!openSubjects)} open={openSubjects}>
                    {subject?.label}
                    <IconBox open={openSubjects}>
                      <img src={'/assets/svg/icon-chevrondown.svg'} alt={'chevron-down'} />
                    </IconBox>
                  </OptionSubjectChoose>
                  <OptionSubjectList open={openSubjects}>
                    {subjects.map((subjectItem: ISubject, index: number) => {
                      return (
                        <OptionSubject
                          key={index}
                          onClick={() => {
                            setSubject(subjectItem)
                            setOpenSubjects(false)
                          }}
                          selected={subjectItem.value === subject?.value}
                        >
                          {subjectItem.label}
                        </OptionSubject>
                      )
                    })}
                  </OptionSubjectList>
                </DropdownSubject>
              </FormGroup>
            </ColCs>
            <ColCs gutter={20} xs={12} md={6}>
              <FormGroup>
                <FormLabel>{t('contact.form.message.label')}</FormLabel>
                <FormTextarea
                  placeholder={t('contact.form.message.placeholder')}
                  rows={14}
                  id={'message'}
                  name={'message'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message ?? ''}
                />
              </FormGroup>
            </ColCs>
          </RowCs>
          <Agree>
            <span>
              <img src={'/assets/svg/icon-protect.svg'} alt={'agree'} />
            </span>
            <span>{t('contact.agree')}</span>
          </Agree>
          <CtaSubmit type="button" disabled={isSubmitting || !isValid || !dirty} onClick={() => handleClick(values)}>
            {isSubmitting ? (
              <>
                <Loader secondary={true} />
                {t('button.submit')}
              </>
            ) : (
              <> {t('button.submit')}</>
            )}
          </CtaSubmit>
        </FormContact>
      </Section>
    </ContactUsWrapper>
  )
}
const ContactUsWrapper = styled.div`
  width: 100%;
`
const ListContact = styled.div`
  padding: 80px 20px;
  width: 100%;
  .col-cs {
    height: auto;
    & > div {
      height: 100%;
    }
  }
  @media screen and (max-width: 991px) {
    padding-top: 40px;
    padding-bottom: 40px;
    .col-cs:last-of-type {
      margin: 30px auto 0;
    }
  }
  @media screen and (max-width: 575px) {
    .col-cs:not(:first-of-type) {
      margin-top: 24px;
    }
  }
`
const CardContent = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  height: 100%;
  a,
  span {
    display: block;
    text-decoration: none;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizeText1};
    line-height: 1.2;
    color: ${({ theme }) => theme.white};
  }
  @media screen and (max-width: 1299px) {
    a,
    span {
      font-size: ${({ theme }) => theme.fontSizeText2};
    }
  }
`
const Illustrator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  height: 64px;
  img {
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 767px) {
    height: 48px;
  }
`
const Introduce = styled.div`
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${({ theme }) => theme.flexColumnCenter};
  gap: 20px;
`
const HighLight = styled(Display1)`
  color: ${({ theme }) => theme.primary};
`
const Title = styled(Heading2)`
  text-transform: capitalize;
`
const Description = styled(TextLarge)`
  line-height: 1.18;
`
const FormContact = styled.form`
  padding: 0 20px 80px;
`
const Asterisk = styled.span`
  color: ${({ theme }) => theme.red};
  margin-left: 4px;
`
const Agree = styled.div`
  display: flex;
  span {
    font-size: ${({ theme }) => theme.fontSizeText3};
    line-height: 20px;

    &:last-of-type {
      margin-left: 8px;
    }
  }
`
const CtaSubmit = styled(ButtonPrimary)`
  margin-top: 32px;
  ${({ theme }) => theme.flexRowCenterVertical};
  gap: 6px;
  &:disabled {
    pointer-events: none;
  }
`
const DropdownSubject = styled.div`
  position: relative;
`
const OptionSubjectChoose = styled.div<{ open: boolean }>`
  padding: 12px 16px;
  border: 1px solid ${({ theme }) => theme.orange};
  border-radius: 4px;
  outline: none;
  width: 100%;
  background-color: ${({ theme }) => theme.dark};
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 24px;
  ${({ theme }) => theme.flexRowCenterVertical};
  justify-content: space-between;
  ${({ open }) =>
    open &&
    css`
      outline: 2px solid ${({ theme }) => theme.primary};
    `};
`
const IconBox = styled.span<{ open: boolean }>`
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  transition: transform 0.3s ease-in;
  img {
    width: 100%;
    height: 100%;
  }
  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
      transform-origin: center;
    `};
`
const OptionSubjectList = styled.ul<{ open: boolean }>`
  list-style: none;
  padding: 0;
  background-color: ${({ theme }) => theme.dark};
  border-radius: 4px;
  position: absolute;
  top: 100%;
  margin-top: 10px;
  left: 0;
  width: 100%;
  z-index: 10;
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease-in;
  ${({ open }) =>
    open &&
    css`
      max-height: 300px;
      outline: 1px solid ${({ theme }) => theme.primary};
    `};
`
const OptionSubject = styled.li<{ selected: boolean }>`
  cursor: pointer;
  padding: 12px 16px;
  transition: background-color 0.25s ease-in;
  &:hover {
    background-color: ${({ theme }) => theme.orange};
  }
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.orange};
      font-weight: 600;
    `};
`
const MessageError = styled(TextNormal)`
  margin-top: 8px;
`
export default Contact
