import { ButtonPrimary } from 'components/Common/Button'
import { Display1, Heading2, TextLarge } from 'components/Common/Typography'
import { IntroduceType } from 'constants/type'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface PropsIntroduceType {
  introduce: IntroduceType
}

const BlockIntroduce = (props: PropsIntroduceType) => {
  const { introduce } = props
  return (
    <BlockIntroduceWrapper>
      <BlockIntroduceContent>
        {introduce?.subTitle && (
            <HighLight>{introduce.subTitle}</HighLight>
        )}
        {introduce?.title && (
            <Title>{introduce.title}</Title>
        )}
          <Description dangerouslySetInnerHTML={{ __html: introduce.description }} />
        {introduce?.link && (
          <Cta to={introduce.link.href} target={'_blank'}>
            <ButtonPrimary>{introduce.link.name}</ButtonPrimary>
          </Cta>
        )}
      </BlockIntroduceContent>
    </BlockIntroduceWrapper>
  )
}
const BlockIntroduceWrapper = styled.div`
  width: 100%;
`
const BlockIntroduceContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const HighLight = styled(Display1)`
  color: ${({ theme }) => theme.primary};
`
const Title = styled(Heading2)`
  @media screen and (max-width: 991px) {
    font-size: ${({ theme }) => theme.heading3};
  }
`
const Description = styled(TextLarge)`
  line-height: 1.18;
  @media screen and (max-width: 991px) {
    font-size: ${({ theme }) => theme.fontSizeText2};
  }
  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.primary};
  }
`
const Cta = styled(Link)`
  text-decoration: none;
  width: max-content;
`
export default BlockIntroduce
