import { IMAGES } from 'assets/images'
import ColCs from 'components/Common/ColCs'
import {Section, SectionWrapper} from 'components/Common/Containers'
import RowCs from 'components/Common/RowCs'
import { TextNormal } from 'components/Common/Typography'
import HeadingSection from 'components/Sections/HeadingSection'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Carousel from "react-multi-carousel";
import {responsive, responsiveSmall} from "constants/Carousel";
import {Link} from "react-router-dom";

const partners = [
  {
    src: IMAGES.ARTTACA,
    link: 'https://arttaca.io/',
  },
  {
    src: IMAGES.EVOTEK,
    link: 'https://evotek.vn/',
  },
  {
    src: IMAGES.SUPERLOGS,
    link: 'https://superalgos.org/',
  },
  {
    src: IMAGES.LAN_CAPITAL,
    link: 'https://www.lantocapital.com/',
  },
  {
    src: IMAGES.ET_HEADER,
    link: 'https://equitiestracker.com/',
  },
  {
    src: IMAGES.ANOTHER1,
    link: 'https://www.another-1.io/',
  },
  {
    src: IMAGES.VSMAC,
    link: 'https://vsmac.com/en/',
  },
  {
    src: IMAGES.DEGEN,
    link: 'https://degengang.io/',
  },
  {
    src: IMAGES.THE_STORM_NETWORK,
    link: 'https://thestormnetwork.com/en',
  },
  {
    src: IMAGES.REMINISCENSE,
    link: 'https://reminiscense.org/',
  },
  {
    src: IMAGES.BLOCKCHAIN_HANOI,
    link: 'https://blockchainhanoi.org/',
  },
  {
    src: IMAGES.SMU,
    link: 'https://www.smu.edu.sg/',
  },
  {
    src: IMAGES.OXYCHAIN,
    link: 'https://oxychain.earth/',
  },
  {
    src: IMAGES.TSS,
    link: 'https://tssnft.com/',
  },
  {
    src: IMAGES.OXY_FARMS,
    link: 'https://oxyfarms.earth/',
  },
  {
    src: IMAGES.LAN_SPACE,
    link: 'https://www.thelanspace.com/',
  },
]
const Partners = () => {
  const { t } = useTranslation()
  return (
    <SectionWrapper>
      <Section>
        <HeadingPartners>
            <HeadingSection content={t('home.partners.heading')} />
            <Description>{t('home.partners.description')}</Description>
        </HeadingPartners>
        <ListPartners>
          <Carousel responsive={responsiveSmall}
                    sliderClass={'slider-ref'}
                    showDots={true}
                    arrows={false}
                    dotListClass={'dot-list'}
                    autoPlay={true}
                    autoPlaySpeed={2000}
          >
            {partners.map((item, index: number) => {
              return (
                <ItemContainer key={index}>
                  <LinkPartner to={item.link} target={'_blank'} rel={'noreferrer'}>
                    <ItemPartner>
                      <div>
                        <img src={item.src} alt={'partner_logo'} />
                      </div>
                    </ItemPartner>
                  </LinkPartner>
                </ItemContainer>
              )
            })}
          </Carousel>
        </ListPartners>
      </Section>
    </SectionWrapper>
  )
}
const HeadingPartners = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
`
const Description = styled(TextNormal)`
  margin-top: 16px;
  text-align: left;
`
const ListPartners = styled.div`
  margin-top: 30px;
  width: 100%;
  .slider-ref {
    margin-right: -15px;
  }
  .dot-list {
    .react-multi-carousel-dot button {
      background-color: ${({ theme }) => theme.primaryShade };
      border: none;
    }
    .react-multi-carousel-dot--active button {
      background-color: ${({ theme }) => theme.primary };
    }
  }
`

const ItemContainer = styled.div`
  width: 100%;
  padding-right: 15px;
`
const LinkPartner = styled(Link)`
  width: 100%;
`
const ItemPartner = styled.div`
  padding: 15px;
  margin-bottom: 30px;
  ${({ theme }) => theme.flexRowCenter};
  width: 100%;
  height: 90px;
  transition: background-color 0.25s ease-in;
  border: 1px solid ${({ theme }) => theme.primaryShade};
  border-radius: 16px;
  &:hover {
    border: 1px solid ${({ theme }) => theme.primary};
  }
  div {
    width: 90%;
    display: block;
  }
  img {
    max-width: 95%;
    margin: 0 auto;
    height: 100%;
    max-height: 80px;
    display: block;
  }
`
export default Partners
