import { ButtonPrimary } from 'components/Common/Button'
import { TextNormal } from 'components/Common/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const BlockContact = () => {
  const { t } = useTranslation()
  return (
    <BlockContactWrapper>
      <BlockContactContainer>
        <BlockContactMain>
          <BlockContactContent>
            <TextNormal>{t('blockContact.content')}</TextNormal>
            <CtaContact to={'/contact'}>
              <ButtonPrimary>{t('button.contactUs')}</ButtonPrimary>
            </CtaContact>
          </BlockContactContent>
          <Image>
            <img src={'/assets/svg/firework-part.svg'} alt={'iconic'} />
          </Image>
        </BlockContactMain>
      </BlockContactContainer>
    </BlockContactWrapper>
  )
}
const BlockContactWrapper = styled.div``
const BlockContactContainer = styled.div`
  padding-top: 190px;
  position: relative;
  @media screen and (max-width: 767px) {
    padding-top: 80px;
  }
`
const BlockContactMain = styled.div`
  width: 100%;
  display: flex;
  max-width: 895px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 991px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 24px;
    padding-right: 24px;
    flex-wrap: wrap;
  }
`
const BlockContactContent = styled.div`
  flex: 0 0 55%;
  max-width: 55%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
  }
`
const CtaContact = styled(Link)`
  text-decoration: none;
  display: block;
  width: max-content;
`
const Image = styled.div`
  flex: 0 0 45%;
  max-width: 45%;
  padding-left: 50px;
  img {
    display: block;
    width: 100%;
  }
  @media screen and (max-width: 991px) {
    padding-left: 24px;
  }
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    order: 1;
    img {
      max-width: 250px;
      width: 100%;
      margin: 0 auto 30px;
    }
  }
`
export default BlockContact
