import AnimationLayout from 'components/Layout/AnimationLayout'
import { PathConstants } from 'constants/PathConstants'
import { AnimatePresence } from 'framer-motion'
import About from 'pages/About'
import Blog from 'pages/Blog'
import Careers from 'pages/Careers'
import Contact from 'pages/Contact'
import Home from 'pages/Home'
import Products from 'pages/Products'
import React from 'react'
import ReactGA from 'react-ga'
import { Route, Routes } from 'react-router-dom'
import styled from 'styled-components'
import "react-multi-carousel/lib/styles.css";

const TRACKING_ID = String(process.env.REACT_APP_NEXT_PUBLIC_GOOGLE_ANALYTICS) // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID)

export default function App() {
  return (
    <AppWrapper>
      <AnimatePresence>
        <Routes>
          <Route element={<AnimationLayout />}>
            <Route path={PathConstants.HOME} element={<Home />} />
            <Route path={PathConstants.ABOUT} element={<About />} />
            <Route path={PathConstants.BLOG} element={<Blog />} />
            <Route path={PathConstants.CAREERS} element={<Careers />} />
            <Route path={PathConstants.CONTACT} element={<Contact />} />
            <Route path={PathConstants.PRODUCTS} element={<Products />} />
            <Route path="*" element={<p>Page Not Found</p>} />
          </Route>
        </Routes>
      </AnimatePresence>
    </AppWrapper>
  )
}

const AppWrapper = styled.div`
  min-height: 100vh;
  overflow: hidden;
`
