import isPropValid from '@emotion/is-prop-valid'
import { useMemo } from 'react'
import {
  createGlobalStyle,
  css,
  DefaultTheme,
  StyleSheetManager,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components'

import { Colors, FontSizes, Shadows } from './styled'
export const MEDIA_WIDTHS = {
  upToExtraSmall: 575,
  upToSmall: 768,
  upToMedium: 1024,
  upToLarge: 1280,
}

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `
  return accumulator
}, {}) as any

function colors(): Colors {
  return {
    white: '#ffffff',
    primary: '#ff4600',
    primaryShade: 'rgba(255,70,0,0.5)',
    primary2: '#07172E',
    dark: '#07172E',
    darkShade: 'rgba(7,23,46,0.5)',
    gray: 'rgba(255,255,255,0.5)',
    gray1: '#E7E7E7',
    gray2: '#A5A5A5',
    gray3: '#576658',
    gray4: '#D5D5D5',
    gray5: '#C1C1C1',
    gray6: '#BFBFBF',
    dark1: '#656565',
    red: '#E44949',
    orange: '#ff6d00',
  }
}

function shadows(): Shadows {
  return {
    boxShadow: '8px 8px 40px 0px rgba(71, 78, 105, 0.8)',
  }
}

function fontSizes(): FontSizes {
  return {
    fontSizeText1: '20px',
    fontSizeText2: '16px',
    fontSizeText3: '14px',
    fontSizeText4: '12px',
    heading1: '60px',
    heading2: '44px',
    heading3: '34px',
    heading4: '24px',
    heading5: '20px',
    heading6: '16px',

    display1: '26px',
    display2: '18px',
  }
}
const fontRoboto = 'Roboto, sans-serif'
const fontMontserrat = 'Montserrat,sans-serif'
const fontDMMono = 'DM Mono, monospace'
const fonts = () => {
  return {
    fontRoboto,
    fontMontserrat,
    fontDMMono,
  }
}
function theme(): DefaultTheme {
  return {
    ...colors(),
    ...fontSizes(),
    ...fonts(),
    ...shadows(),
    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    buttons: {
      primary: {
        bg: 'transparent',
      },
      secondary: {
        bg: 'primary',
        px: 5,
      },
      secondary_1: {
        bg: 'primary',
      },
    },

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
    flexRowCenter: css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    `,
    flexRowCenterVertical: css`
      display: flex;
      align-items: center;
      flex-direction: row;
    `,
    flexColumn: css`
      display: flex;
      flex-direction: column;
    `,
    flexColumnCenterHoz: css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
    flexColumnCenter: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const themeObject = useMemo(() => theme(), [])

  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
    </StyleSheetManager>
  )
}

export const ThemedGlobalStyle = createGlobalStyle`
  html {
      color: white;
  }
  body {
    height: 100%;
    background-color: ${({ theme }) => theme.dark};
  }
  p{
    font-size: ${({ theme }) => theme.fontSizeText2};
    color: ${({ theme }) => theme.gray4};
    line-height: 1.25;
    margin: 0;
  }
`
